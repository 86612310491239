<template>
  <v-row>
    <v-row class="w-full pa-2">
      <v-alert
        v-model="addListAlert"
        dismissible
        class="w-full ma-1"
        type="error"
        transition="slide-y-transition"
        elevation="2"
      >
        {{ errorMsg }}
      </v-alert>
    </v-row>

    <v-card class="col-12 mt-6 pa-6 mb-6">
      <v-row>
        <v-text-field
          v-model="newListName"
          class="col-auto col-lg mr-4"
          hide-details="auto"
          dense
          outlined
          style="min-width: 70%;"
          label="New List Name"
          @keyup.enter="addList"
        ></v-text-field>
        <v-btn
          outlined
          style="margin-top: 1px"
          class="col-2"
          :disabled="!this.newListName"
          @click="addList"
        >
          Add List
        </v-btn>
      </v-row>
    </v-card>

    <v-row>
      <div
        v-for="(list, j) in [processedList.uncategorized, ...Object.values(processedList.lists || [])]"
        v-if="list && !list._deleted"
        :key="j"
        class="col-12 w-full"
      >
        <v-card
          class="col-12 w-full pt-0"
        >
          <v-card-title
            v-if="!isUncategorized(list)"
            class="pa-2 pt-4 pb-5 mb-2 list-title"
          >
            {{ list.name }}
          </v-card-title>
          <v-row>
            <v-simple-table class="w-full">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(element, i) in getListTasks(list)"
                    v-if="!element._deleted"
                    :key="i"
                    :class="{'pt-4': (i === 0)}"
                    class="list-group-item w-full row"
                  >
                    <td class="col-1 pa-0 pl-12">
                      <v-checkbox
                        class="mt-1"
                        dense
                        v-model="element.favorite"
                        :off-icon="icons.mdiStarOutline"
                        :on-icon="icons.mdiStar"
                        @change="taskChanged(element)"
                      ></v-checkbox>
                    </td>
                    <td class="col-5 pa-0 pr-2">
                      <v-text-field
                        v-model="element.label"
                        filled
                        dense
                        class="information-field ps-4"
                        hide-details="auto"
                        placeholder="Label"
                        v-bind="{autofocus: !element.label}"
                        @focusout="textFocusOut(element)"
                        @change="taskChanged(element)"
                      ></v-text-field>
                    </td>

                    <td class="col-5 pa-0 ps-2">
                      <v-text-field
                        v-model="element.link"
                        filled
                        dense
                        class="information-field pa-0"
                        hide-details="auto"
                        placeholder="Link"
                        @focusout="textFocusOut(element)"
                        @keyup.enter="addLink(list)"
                        @change="taskChanged(element)"
                      ></v-text-field>
                    </td>

                    <td class="col-1">
                      <v-icon
                        dense
                        class="delete-task-btn ml-3 mt-2"
                        @click="removeTask(element)"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </td>
                  </tr>

                  <tr class="text-center row">
                    <td
                      colspan="5"
                      class="pa-3 mb-3 col-12 w-full"
                      :class="{'mt-3': !getListTasks(list).length}"
                    >
                      <button
                        class="btn btn-secondary button"
                        @click="addLink(list)"
                      >
                        <span
                          v-if="!getListTasks(list).length"
                          class="text-center pa-4 pr-1"
                        >
                          No Links Yet, Add First
                        </span>
                        <v-icon class="pr-3">
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <span
                          v-if="!getListTasks(list).length && !isUncategorized(list)"
                          @click="removeList(list)"
                        >
                          Or Delete This List
                        </span>
                        <v-icon
                          v-if="!getListTasks(list).length && !isUncategorized(list)"
                          class="pl-1"
                          @click="removeList(list)"
                        >
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-card>
      </div>
    </v-row>
  </v-row>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapState } from 'vuex'
import {
  mdiPlus,
  mdiDelete,
  mdiAccount,
  mdiCalendar,
  mdiClose,
  mdiAccountPlus,
  mdiStickerEmoji,
  mdiStar,
  mdiStarOutline,
} from '@mdi/js'

export default {
  components: {
    Draggable,
  },
  data() {
    return {
      errorMsg: '',
      addListAlert: false,

      newListName: '',
      menu2: false,

      icons: {
        mdiClose,
        mdiDelete,
        mdiAccount,
        mdiStickerEmoji,
        mdiAccountPlus,
        mdiCalendar,
        mdiStar,
        mdiStarOutline,
        mdiPlus,
      },

      processedList: {},

      filters: {},
    }
  },
  computed: {
    ...mapState({
      links: 'quickLinksLinks',
      lists: 'quickLinksLists',
      currentProject: 'currentProject'
    }),
  },
  watch: {
    async currentProject() {
      await this.$store.dispatch('setSectionOverlay', true)
      await this.$store.commit('SET_QUICK_LINKS_LISTS', [])
      await this.$store.commit('SET_QUICK_LINKS_LINKS', [])
      await this.$store.dispatch('fetchLinks')
      await this.$store.dispatch('setSectionOverlay', false)
    },
    links() { this._reProcessList() },
    lists() { this._reProcessList() },
  },
  async mounted() {
    await this.$store.dispatch('setSectionOverlay', true)
    await this.$store.dispatch('fetchLinks')
    await this.$store.dispatch('setSectionOverlay', false)
  },
  methods: {
    addList() {
      if (!this.newListName) {
        return
      }

      const newList = { name: this.newListName, _new: true }
      newList._internal_key = this.__getListKey(newList)

      let listExists = false
      Object.values(this.lists).map(list => {
        if (this.__getListKey(list) === newList._internal_key) {
          listExists = true
        }
      })
      if (listExists) {
        this.__showError('Error creating new list. List with the same name already exists.')

        return
      }

      this.lists.push(newList)

      this.newListName = ''

      this.addLink(newList)
      this.listChanged()
    },
    removeList(list) {
      list._deleted = true
      this.listChanged()
    },

    _reProcessList() {
      const processedList = {
        uncategorized: {
          _uncategorized: true,
          links: [],
        },
        lists: {},
      }

      this.lists.map(list => {
        if (!list.links) {
          list.links = []
        }
        processedList.lists[list.id || this.__getListKey(list)] = list
      })

      const allTasks = this.links.slice()
      Object.values(processedList.lists).map(list => {
        list.links = []
      })

      allTasks.map(task => {
        if (task.list_id && processedList.lists[task.list_id]) {
          processedList.lists[task.list_id].links.push(task)
        } else if (task.list_internal_key && processedList.lists[task.list_internal_key]) {
          processedList.lists[task.list_internal_key].links.push(task)
        } else {
          processedList.uncategorized.links.push(task)
        }
      })

      this.processedList = processedList

      this.$forceUpdate()
    },

    addLink(list) {
      const task = {
        favorite: false, label: '', link: '', _new: true, _skip_processing: true
      }
      if (list) {
        if (list.id) task.list_id = list.id
        else task.list_internal_key = list._internal_key
      }

      let haveEmpty = false
      this.getListTasks(list).map(task => {
        if (!task._deleted && (!task.label || !task.link)) {
          haveEmpty = true
        }
      })

      if (haveEmpty) {
        return
      }

      this.links.push(task)
      this.taskChanged(task)
    },
    async removeTask(task) {
      task._deleted = true
      if (!task._in_sync) {
        task._skip_processing = false
      }
      await this.$store.dispatch('setQuickLinksLinks', this.links)
    },

    getListTasks(list) {
      return (list.links || []) || []
    },

    async taskChanged(initialTask) {
      initialTask._synced = false
      this.links.map(link => {
        if (link.label && link.link) {
          delete link._skip_processing
        } else {
          link._skip_processing = true
        }
      })
      await this.$store.dispatch('setQuickLinksLinks', this.links)
    },
    listChanged() {
      this.$store.dispatch('setQuickLinksLists', this.lists)
    },

    __getListKey(list) {
      return JSON.stringify({
        name: list.name,
      })
    },

    __showError(text) {
      this.errorMsg = text
      this.addListAlert = true
      setTimeout(() => {
        this.addListAlert = false
        this.errorMsg = 'false'
      }, 5000)
    },

    isUncategorized(list) {
      return !!list._uncategorized
    },

    textFocusOut(element) {
      this.$nextTick(() => {
        if (!element.label && !element.link) {
          this.removeTask(element)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.information-field.v-text-field > .v-input__control > .v-input__slot:before {
  display: none;
}
.no-msg-icon .v-messages {
  display: none;
}
.delete-task-btn,
.delete-list-btn {
  opacity: 0;
  transition: .25s;
}
.list-group-item:hover .delete-task-btn,
.list-title:hover .delete-list-btn {
  opacity: 1;
}
.delete-list-btn {
  margin-left: auto;
}
.emoji-picker {
  position: absolute;
  z-index: 1000;

  &.hidden {
    display: none!important;
  }
}
.new-list-emoji-container {
  color: #fff;
  padding: 12px 24px 0!important;
  display: block;
  cursor: pointer;
}
.list-title-emoji {
  color: #fff;
}

.list-group-item.finished .information-text {
  text-decoration: line-through;
  opacity: 0.9;
}

.task-assigned-avatar {
  cursor: pointer;
}

.date-input {
  width: 0;
  margin: 0;
  padding: 0;

  &.active {
    width: 150px;
  }
}
.information-field {
  margin-top: -1px!important;
}
.list-group-item {
  margin-left: 0;
}
.list-group-item > td {
  height: 2.5rem!important;
}
</style>
